import { useState } from 'react';
import './App.css';
import UAParser from 'ua-parser-js';
const parser = new UAParser();

const downloadPrefix = "https://s3.us-west-1.amazonaws.com/com.parsegraph.freeform"
const setupFileName = "parsegraph-freeform-1.1.0+Setup.exe";

function BasicOSDownload({os, path, label}) {
  return <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
    <img src="/fp-images/freeform-first-page.png" style={{width: '30%'}}></img>
  <div style={{padding: '0 1em', boxSizing: 'border-box'}}>
    <h3>Get Parsegraph Freeform for {os}!</h3>
    <a href={`${downloadPrefix}/${path}`}>
      {label}
    </a>
  </div></div>;
}

const downloads = {
  "win": [{
    path: `latest/squirrel.windows/x64/${setupFileName}`,
    label: "Parsegraph Freeform (Windows 64-bit)"
  },
  {
    path: `latest/squirrel.windows/x64/parsegraph-freeform-1.1.0-full.nupkg`,
    label: "Parsegraph Freeform - nupkg (Windows 64-bit)"
  }],
  "mac": [{
    path: "latest/zip/darwin/x64/parsegraph-freeform-darwin-x64-1.1.0.zip",
    label: "Parsegraph Freeform (Darwin x64)"
  }],
  "rpm": [{
    path:"latest/rpm/x64/parsegraph-freeform-1.1.0-1.x86_64.rpm",
    label:"Parsegraph Freeform (x86_64.rpm)"
  }],
  "deb": [{
    path:"latest/deb/x64/parsegraph-freeform_1.1.0_amd64.deb",
    label:"Parsegraph Freeform (amd64.deb)"
  }]
};

const PlatformDownload = ({os, platform}) => { 
  const link = downloads[platform][0];
  return <BasicOSDownload
    os={os}
    path={link.path}
    label={link.label}
  />;
};

const PlatformDownloads = ({platform, name}) => { 
  return <>
    <h4>{name}</h4>
    <ul>{downloads[platform].map(link => 
    <li key={link.path}><a href={`${downloadPrefix}/${link.path}`}>
      {link.label}
    </a></li>)}</ul></>;
};

function ShowAllDownloads() {
  return <div style={{padding: '1em', boxSizing: 'border-box'}}>
    <h3 style={{margin: '0'}}>Install Parsegraph Freeform on Linux</h3>
    <p style={{margin:'1em 0', color: 'lightgrey'}}>Please select your distro.</p>
    <PlatformDownloads name="Mint, Ubuntu, Debian" platform="deb"/>
    <PlatformDownloads name="Fedora, CentOS, RedHat" platform="rpm"/>
    <p>
      Don't see the one you're using? <a href="https://github.com/parsegraph/freeform/issues/new">Open a ticket</a>
    </p>
    <p>
      <a href="https://github.com/parsegraph/freeform">Want to build from source?</a>
    </p>
  </div>;
}

function App() {
  const os = parser?.getResult()?.os?.name;

  const getDownloadForOS = (os) => {
    console.log(os);
    switch (os) {
    case "Linux":
      return <ShowAllDownloads />;
    case "Fedora":
    case "CentOS":
    case "RedHat":
      return <PlatformDownload os={os} platform="rpm" />
    case "Ubuntu":
    case "Debian":
    case "Mint":
      return <PlatformDownload os={os} platform="deb" />
    // TODO These need certificates before they can be released
    /*case "Windows":
    case "Windows Phone":
    case "Windows Mobile":
      return <PlatformDownload os={os} platform="win" />
    case "Mac OS":
      return <PlatformDownload os={os} platform="mac" />*/
    }
  }

  const knownOS = !!getDownloadForOS(os);

  return (knownOS ? 
    <div className="download" style={{display: knownOS ? 'flex' : 'none', gap: '10px', flexDirection: 'column', alignItems: 'center', padding: '0 0.5em', boxSizing: 'border-box'}}>
      {getDownloadForOS(os)}
    </div> : null
  );
}

export default App;
